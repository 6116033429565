<template>
  <WorkspaceLayout>
    <slot />
  </WorkspaceLayout>
</template>

<script lang="ts" setup>
import WorkspaceLayout from '#core/layouts/workspace.vue'
import type { BaseBoard } from '#board/types'
import type { GroupBy } from '#board/constant'

const boardId = computed(() => currentBoard.value.id)

const { resetFilter } = useTaskFilterContext()
const { currentBoard } = useWorkspaceSharedState()
const { subscribe, unsubscribe } = useBoardRealtimeContext()
const { socket, initialize } = useSocket()
const { load, refetch, onResult } = useBoardDataLazyQuery(boardId.value, {
  fetchPolicy: 'cache-first',
})
const { boardData, viewOptions, groupBy } = useBoardSharedState()
const { setCurrentBoard } = useWorkspaceSharedState()
const { onResult: onMemberOptionQueryResult } = useBoardMemberOptionsQuery(
  boardId.value
)

useBoardStateUpdater()

onResult((result) => {
  if (result.data?.boardData) {
    boardData.value = result.data.boardData
  }
})

onMemberOptionQueryResult((result) => {
  const options = result.data?.boardMemberOptions?.viewOptions
  viewOptions.value = safeParseStringToJSON(options?.toString(), {})
  groupBy.value = result.data?.boardMemberOptions?.groupBy as GroupBy
})

const handleAccessBoard = () => {
  if (boardId.value) {
    subscribe(boardId.value)
    const { mutate } = useSetActiveBoardMutation(boardId.value)
    mutate()
  }
}

watch(
  () => boardId.value,
  (newBoardId, oldBoardId) => {
    if (newBoardId) {
      resetFilter()
      handleAccessBoard()
    }

    if (oldBoardId) {
      unsubscribe(oldBoardId)
    }

    refetch({
      boardId: newBoardId,
    })
  }
)

onBeforeMount(() => {
  load()
  initialize()
  handleAccessBoard()
})

onUnmounted(() => {
  socket.value?.terminate()
  setCurrentBoard({} as BaseBoard)
})
</script>
