import { pick } from 'lodash-es'
import type { ServerCustomMessagePayload, ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { BaseBoard } from '#board/types'
import type { FieldsModifier } from '#core/types'

export const useBoardSync = () => {
  const { client } = useApolloClient()

  const getSyncFields = (payload: Partial<BaseBoard>) => {
    const fields: FieldsModifier<BaseBoard> = {
      name: () => payload.name,
      visibility: () => payload.visibility,
      backgroundColor: () => payload.backgroundColor,
      backgroundType: () => payload.backgroundType,
      backgroundPhoto: () => payload.backgroundPhoto,
      description: () => payload.description,
      closed: () => payload.closed,
    }

    return pick(fields, Object.keys(payload)) as FieldsModifier<BaseBoard>
  }

  const onUpdate = (payload: Partial<BaseBoard>) => {
    client.cache.modify({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'BoardType',
      }),
      fields: getSyncFields(payload),
    })
  }

  const onDelete = async (payload: Partial<BaseBoard>) => {
    await navigateTo('/')
    client.cache.evict({
      id: client.cache.identify({
        id: payload.id,
        __typename: 'BoardType',
      }),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<BaseBoard>) => void
        >([
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}

export const useImportTasksSync = () => {
  const handle = (payload: ServerCustomMessagePayload) => {
    const { importFiles, showButtonImport } = useBoardImportTasksSharedState()
    const toast = useToast()

    const { data, board_id: boardId } = payload
    const { file_name: fileName, success } = data

    importFiles.value.map((file) => {
      if (file.fileName === fileName && file.boardId === boardId) {
        if (success) {
          file.remain -= 1
        } else {
          file.status = 'failed'
          return
        }

        if (file.remain === 0) {
          file.status = 'completed'
          toast.add({
            title: 'Imported task successfully',
            description: `All tasks from ${fileName} have been imported successfully`,
            icon: 'i-heroicons-check-circle',
          })
          // Hide button after 1 minute
          setTimeout(() => {
            showButtonImport.value = false
          }, 60000)
        }
      }

      return file
    })
  }

  return {
    handle,
  }
}
